'use client'

import { ChevronLeft16, ChevronRight16, X16 } from '@vinted/monochrome-icons'
import { Icon, Loader } from '@vinted/web-ui'
import classNames from 'classnames'
import { noop } from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { KeyboardKey, useWindowEvent } from '@marketplace-web/shared/browser'

import OutsideClick from '../OutsideClick'

type Props = {
  imageSources: Array<string>
  initialImageIndex?: number
  onClose?: () => void
  onImageChange?: (index: number, direction: 'left' | 'right') => void
}

const ImageCarousel = ({
  imageSources,
  initialImageIndex = 0,
  onClose = noop,
  onImageChange = noop,
}: Props) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(initialImageIndex)
  const [loadedImageIndexes, setLoadedImageIndexes] = useState<Array<number>>([])

  const showPreviousImage = () =>
    setCurrentImageIndex(index => {
      const newIndex = index > 0 ? index - 1 : imageSources.length - 1
      onImageChange(newIndex, 'left')

      return newIndex
    })

  const showNextImage = () =>
    setCurrentImageIndex(index => {
      const newIndex = index + 1 < imageSources.length ? index + 1 : 0
      onImageChange(newIndex, 'right')

      return newIndex
    })

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.code) {
      case KeyboardKey.ArrowLeft:
      case KeyboardKey.Backspace:
        showPreviousImage()
        break
      case KeyboardKey.ArrowRight:
      case KeyboardKey.Enter:
        showNextImage()
        break
      case KeyboardKey.Escape:
        onClose()
        break
      case KeyboardKey.Tab:
        event.preventDefault()
        break
      default:
        break
    }
  }

  useWindowEvent('keydown', handleKeyDown)

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  const handleImageLoad = (imageIndex: number) => () => {
    setLoadedImageIndexes(indexes => [...indexes, imageIndex])
  }

  function renderImage(src: string, index: number) {
    const isLoaded = loadedImageIndexes.includes(index)
    const isShown = index === currentImageIndex
    const showLoader = isShown && !isLoaded

    return (
      <Fragment key={index}>
        {showLoader && <Loader />}
        <img
          src={src}
          alt="post"
          data-testid={`image-carousel-image${isShown ? '-shown' : ''}`}
          className={classNames(
            'image-carousel__image',
            isShown ? 'image-carousel__image--shown' : null,
          )}
          onLoad={handleImageLoad(index)}
        />
      </Fragment>
    )
  }

  function renderCarouselIcon(name: ComponentProps<typeof Icon>['name']) {
    return (
      <div className="image-carousel__icon-wrapper">
        <Icon name={name} color={Icon.Color.GreyscaleLevel7} />
      </div>
    )
  }

  if (!imageSources.length) return null

  const showControls = imageSources.length > 1

  return (
    <div className="image-carousel" data-testid="image-carousel">
      <OutsideClick onOutsideClick={onClose}>
        <div className="image-carousel__image-wrapper">
          {showControls && (
            <>
              <button
                type="button"
                className="image-carousel__button"
                data-testid="image-carousel-button-left"
                onClick={showPreviousImage}
              >
                {renderCarouselIcon(ChevronLeft16)}
              </button>
              <button
                type="button"
                className="image-carousel__button image-carousel__button--right"
                data-testid="image-carousel-button-right"
                onClick={showNextImage}
              >
                {renderCarouselIcon(ChevronRight16)}
              </button>
            </>
          )}
          <button
            type="button"
            className="image-carousel__button image-carousel__button--close"
            data-testid="image-carousel-button-close"
            onClick={onClose}
          >
            {renderCarouselIcon(X16)}
          </button>
          {imageSources.map(renderImage)}
        </div>
      </OutsideClick>
    </div>
  )
}

/** @deprecated use @vinted/web-ui Carousel component */
export default (props: Props) => createPortal(<ImageCarousel {...props} />, document.body)
