import { ColorCode } from '../constants/ui'

const colorCodeHexMap: Partial<Record<ColorCode, string>> = {
  // primary
  [ColorCode.Primary]: '#007782',
  [ColorCode.Success]: '#28865a',
  [ColorCode.Expose]: '#f9bb42',
  [ColorCode.Warning]: '#d04555',
  // grayscale
  [ColorCode.GreyscaleLevel1]: '#171717',
  [ColorCode.GreyscaleLevel2]: '#4d4d4d',
  [ColorCode.GreyscaleLevel3]: '#757575',
  [ColorCode.GreyscaleLevel4]: '#999',
  [ColorCode.GreyscaleLevel5]: '#c9c9c9',
  [ColorCode.GreyscaleLevel6]: '#f2f2f2',
  // [ColorCode.GreyscaleLevel7]: '#fff',
  // vendor
  [ColorCode.Facebook]: '#3b5998',
  [ColorCode.Google]: '#4285f4',
  [ColorCode.Apple]: '#000000',
}

export function colorCodeToHex(code: ColorCode): string | undefined {
  return colorCodeHexMap[code]
}
