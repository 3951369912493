'use client'

import {
  forwardRef,
  Component,
  ReactNode,
  Ref,
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
  MouseEvent,
} from 'react'
import classNames from 'classnames'
import TextareaAutosize from 'react-textarea-autosize'

import { idFromName } from '../../utils/html'

export type Props = {
  name: string
  validation?: ReactNode
  title?: string
  value?: string | number | null
  minLength?: number
  maxLength?: number
  icon?: JSX.Element
  placeholder?: string
  styling?: 'narrow' | 'wide' | 'tight'
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void
  onKeyPress?: (event: KeyboardEvent<HTMLTextAreaElement>) => void
  onKeyDown?: (event: KeyboardEvent<HTMLTextAreaElement>) => void
  onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void
  onInputClick?: (event: MouseEvent<HTMLTextAreaElement>) => void
  maxRows?: number
  forwardedRef?: Ref<HTMLTextAreaElement>
  uncontrolled?: boolean
}

type DefaultProps = {
  required: boolean
  disabled: boolean
  rows: number
}

type State = {
  inputValue: string | number | null
}

/** @deprecated Use @vinted/web-ui version of InputTextArea instead or provide reasons why you must use this one */
class DeprecatedInputTextarea extends Component<Props & DefaultProps, State> {
  static defaultProps: DefaultProps = {
    required: false,
    disabled: false,
    rows: 5,
  }

  state = {
    inputValue: this.props.value || '',
  }

  handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { onChange, uncontrolled } = this.props

    if (!uncontrolled) this.setState({ inputValue: event.target.value || '' })

    if (onChange) onChange(event)
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        inputValue: this.props.value || '',
      })
    }
  }

  render() {
    const {
      name,
      validation,
      title,
      required,
      disabled,
      minLength,
      maxLength,
      icon,
      placeholder,
      styling,
      rows,
      maxRows,
      forwardedRef,
      uncontrolled,
      onKeyPress,
      onKeyDown,
      onFocus,
      onInputClick,
    } = this.props

    const inputClass = classNames('c-input', {
      [`c-input--${String(styling)}`]: styling !== undefined,
    })

    return (
      <label htmlFor={name} className={inputClass}>
        {title && <div className="c-input__title">{title}</div>}
        <div className="c-input__content">
          <TextareaAutosize
            className="c-input__value"
            name={name}
            id={idFromName(name)}
            value={uncontrolled ? undefined : this.state.inputValue}
            required={required}
            disabled={disabled}
            minLength={minLength}
            maxLength={maxLength}
            placeholder={placeholder}
            onChange={this.handleChange}
            onClick={onInputClick}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            minRows={rows}
            ref={forwardedRef}
            maxRows={maxRows}
          />
          {icon && <div className="c-input__icon">{icon}</div>}
        </div>

        {validation && <div className="c-input__note">{validation}</div>}
      </label>
    )
  }
}

/** @deprecated Use @vinted/web-ui version of InputTextArea instead or provide reasons why you must use this one */
const DeprecatedInputTextareaWithForwardedRef = forwardRef(
  (props: Props & Partial<DefaultProps>, ref?: Ref<HTMLTextAreaElement>) => (
    <DeprecatedInputTextarea forwardedRef={ref} {...props} />
  ),
)

export default DeprecatedInputTextareaWithForwardedRef
