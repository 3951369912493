import { useCallback } from 'react'

import useAsset from '../useAsset'

/** @deprecated use useAsset instead */
function useImageAssetsUrl() {
  const asset = useAsset()

  return useCallback(
    (filePath: string) => asset(filePath, { theme: { dark: filePath.replace('.', '-dark.') } }),
    [asset],
  )
}

export default useImageAssetsUrl
