import { FormattedMessage } from 'react-intl'

import { Text } from '@vinted/web-ui'

const DefaultText = () => (
  <Text
    text={<FormattedMessage id="dropzone.description" />}
    as="h1"
    type={Text.Type.Heading}
    theme="muted"
  />
)

export default DefaultText
