'use client'

import { createRef, Component, ReactNode, MouseEvent } from 'react'

type Props = {
  onClick?: () => void
  children?: ReactNode
}

class Overlay extends Component<Props> {
  node = createRef<HTMLDivElement>()

  onClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target !== this.node.current) return

    if (this.props.onClick) this.props.onClick()
  }

  render() {
    return (
      <div className="page-overlay" role="presentation" onClick={this.onClick} ref={this.node}>
        {this.props.children}
      </div>
    )
  }
}

export default Overlay
