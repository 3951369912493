'use client'

import { Cell, Radio } from '@vinted/web-ui'
import { AriaAttributes, ReactNode, useEffect, useState } from 'react'

import { useBreakpoint } from '@marketplace-web/shared/breakpoints'

import List from './List'
import useRunAfterRerender from '../hooks/useRunAfterRerender'
import InputDropdown, { InputDropdownRenderProps } from './InputDropdown'

export type InputDropdownSelectType = {
  id: number | string
  title: string | null | undefined
  body?: string | null
  isDisabled?: boolean
}

type Props<T extends InputDropdownSelectType> = {
  data?: Array<T> | undefined | null
  name: string
  group?: string
  inputTitle: string
  placeholder?: string
  onSave: (value?: T | null) => void
  onOpen?: () => void
  disabled?: boolean
  isLoading?: boolean
  isDirectionUp?: boolean
  selectedValue?: T | null
  validation?: ReactNode
  required?: boolean
  aria?: AriaAttributes
  closeOnDropdownBlur?: boolean
  testId?: string
  isBackgroundTransparent?: boolean
}

const SelectableDropdown = <T extends InputDropdownSelectType>({
  data = [],
  name,
  group,
  inputTitle,
  placeholder,
  onSave,
  onOpen,
  disabled,
  isLoading,
  isDirectionUp,
  selectedValue: selectedValueFromProp = null,
  validation,
  required,
  aria,
  closeOnDropdownBlur,
  testId,
  isBackgroundTransparent,
}: Props<T>) => {
  const [selectedValue, setSelectedValue] = useState<T | null>(selectedValueFromProp)
  const breakpoints = useBreakpoint()
  const runAfterRerender = useRunAfterRerender()

  useEffect(() => {
    setSelectedValue(selectedValueFromProp)
  }, [selectedValueFromProp])

  const isSelectedValue = (itemId: string | number) => {
    return !!selectedValue && selectedValue.id === itemId
  }

  const handleListClick = (item: T, inputRenderProps: InputDropdownRenderProps) => () => {
    setSelectedValue(item)

    runAfterRerender(() => {
      inputRenderProps.setIsSaveEnabled(true)
      if (!breakpoints.phones) inputRenderProps.closeDropdown()
    })
  }

  const renderSelectableList = (inputRenderProps: InputDropdownRenderProps): ReactNode => {
    if (!data) return null

    return (
      <List>
        {data.map(item => {
          if (!item?.title) return null

          const id = group ? `${group}-${item.id.toString()}` : item.id.toString()

          return (
            <Cell
              id={id}
              key={id}
              title={item.title}
              body={item.body}
              suffix={
                <Radio
                  tabIndex={-1}
                  name={`${id}-radio`}
                  checked={isSelectedValue(item.id)}
                  aria={{ 'aria-labelledby': id }}
                  onChange={() => undefined}
                />
              }
              onClick={handleListClick(item, inputRenderProps)}
              disabled={item.isDisabled}
              aria={{ 'aria-disabled': item.isDisabled }}
              clickable
            />
          )
        })}
      </List>
    )
  }

  const handleCancel = () => setSelectedValue(selectedValueFromProp)

  const handleSave = () => {
    if (selectedValue === selectedValueFromProp) return

    setSelectedValue(null)

    runAfterRerender(() => {
      onSave(selectedValue)
    })
  }

  return (
    <InputDropdown
      name={name}
      title={inputTitle}
      placeholder={placeholder}
      readOnly
      isDirectionUp={isDirectionUp}
      disabled={disabled}
      isLoading={isLoading}
      render={renderSelectableList}
      onCancel={handleCancel}
      onOpen={onOpen}
      onSave={handleSave}
      onClose={handleSave}
      validation={validation}
      value={selectedValue?.title}
      required={required}
      closeOnDropdownBlur={closeOnDropdownBlur}
      aria={aria}
      testId={testId}
      isBackgroundTransparent={isBackgroundTransparent}
    />
  )
}

export default SelectableDropdown
