'use client'

import { useEffect, useState } from 'react'

import { serverSide } from '@marketplace-web/shared/environment'

// This hook is intended as a workaround. Using it should be considered technical debt
function useClientSideOnly() {
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    setShouldRender(!serverSide)
  }, [])

  return shouldRender
}

export default useClientSideOnly
